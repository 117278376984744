@import "../../../../../styles/helpers";

.body {
    position: static;
    margin: 0;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    .wrap {
        align-self: center;
        position: relative;
        background-color: $n2;
        padding: 12px 4px;
        border-radius: 8px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap-between: 24px; }
    @include body2-s;
    .button {
        width: auto;
        margin: 0 12px; } }

.field {
    margin-bottom: 0; }

