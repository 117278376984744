@import "../../styles/helpers";

.footer {
    position: relative;
    bottom: 0;
    left: 0 !important;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // min-width: -webkit-fill-available;
    align-items: center;
    padding: 24px 40px;
    background: $n;
    // color: $dark
    // border-top: 1px solid $n3
    // box-shadow: inset 1px 0px 0px $n2, inset 0 -1px 0px $n3
    @include x {
        padding: 16px 24px; }
    &.active {
        @include d {
            // align-items: center
            left: 0;
            right: 0;
            width: 100%;
            // min-width: -webkit-fill-available;
            box-shadow: 4px 0 32px rgba($n8, .05);
            .item,
            .link {
                width: 100%;
                font-size: 15px;
                svg {
                    margin-right: 12px; } }
            .toggle {
                position: absolute;
                top: 16px;
                right: 16px;
                svg {
                    fill: $n7;
                    &:first-child {
                        display: none; }
                    &:nth-child(2) {
                        display: inline-block; } } } }
        @include m {
            width: 100%;
            // min-width: -webkit-fill-available;
            left: 0;
            right: 0; } } }

.logo {
    display: block;
    align-self: auto;
    width: auto;
    left: 0;
    img {
        height: 24px;
        width: 100%;
        left: 0; } }

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    gap: 24px;
    color: $dark;
    svg {
        margin-right: 12px;
        fill: $dark;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 0px; } }
    @include x {
        flex-direction: row;
        margin-right: 0; } }

.copyright {
    position: relative;
    width: auto;
    height: auto;
    margin-right: auto;
    margin-left: 24px;
    font-weight: 400;
    text-align: center;
    color: $shades1;
    @include t {
        font-size: 11px; }
    @include s {
        display: none; } }


.button {
    &:not(:last-child) {
        margin-right: 24px; }
    @include t {
        display: flex; } }

.btns {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;
    @include m {
        margin-left: 0;
        margin-right: 0; } }

.item,
.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
    height: 48px;
    width: 100%;
    white-space: nowrap;
    color: $dark;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: color .2s;
    @include d {
        width: 48px;
        font-size: 0; }
    @include m {
        width: 100%;
        font-size: 15px; }
    @include button1;
    color: $dark;
    transition: color .2s;
    svg {
        margin-right: 12px;
        fill: $dark;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 12px; } }
    &:hover {
        color: $p1; }
    &.active {
        color: p1; } }

