@import "../../../styles/helpers";

.menu {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-left: auto;
    @include t {
        display: none; } }


.toggle {
    display: none;
    width: 48px;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include d {
        display: inline-block; }
    @include m {
        display: none; }
    svg {
        fill: $n4;
        &:nth-child(2) {
            display: none; } } }

.burger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    @include d {
        display: flex;
        position: absolute;
        top: 16px;
        right: 16px;
        flex-direction: column;
        width: 48px;
        height: 48px; }
    &:before,
    &:after {
        content: "";
        width: 16px;
        height: 2px;
        border-radius: 2px;
        background: $n4; }
    &:before {
        margin-bottom: 6px; } }

.button {
    &:not(:last-child) {
        margin-right: 24px; }
    @include t {
        display: none; } }

.btns {
    display: flex;
    align-items: center;
    margin-left: auto;
    @include d {
        margin-left: 0; } }

.item,
.link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 32px;
    height: 48px;
    width: 100%;
    white-space: nowrap;
    color: $n4;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: color .2s;
    @include d {
        width: 100%;
        font-size: 15px;
        margin-right: 24px; }
    @include t {
        width: 100%;
        font-size: 15px;
        flex-direction: column;
        align-items: center;
        @include button1;
        color: $n7;
        transition: color .2s; }
    svg {
        margin-right: 12px;
        fill: $n4;
        transition: fill .2s;
        @include d {
            margin-right: 0; }
        @include m {
            margin-right: 12px; } }
    &:hover {
        color: $p1; }
    &.active {
        color: p1; } }


.close {
    display: none;
    @include d {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 16px;
        right: 16px;
        width: 48px;
        height: 48px;
        svg {
            fill: $n7; } } }

.overlay {
    @include d {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 12;
        background: rgba($n2, .9);
        visibility: hidden;
        opacity: 0;
        transition: all .2s;
        &.active {
            visibility: visible;
            opacity: 1; } } }
