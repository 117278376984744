@import "../../../styles/helpers";

// .hero
//     height: 640px
//     background-color: #ffffff
//     margin: -40px -40px 0
//     +x
//         margin: -32px -24px 0
//     +d
//         height: 320px
//     +m
//         height: 252px
//         margin: -24px -16px 0
//     img
//         width: 100%
//         height: 100%
//         object-fit: cover

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(50%);
        width: calc(50%);
        padding-right: 8px;
        @include x {
            flex: 0 0 calc(50%);
            width: calc(50%); }
        @include d {
            flex: 0 0 calc(50%);
            width: calc(100% - 312px); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: cal(50%);
        @include x {
            width: cal(50%); }
        @include d {
            width: cal(50%); }
        @include t {
            width: 100%; } } }


.list {
    display: flex;
    flex-wrap: wrap;
    margin: -80px -80px 0;
    @include w {
        margin: -64px -64px 0; }
    @include t {
        display: block;
        margin: 0; } }


.full {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    // gap: 48px
    // margin: 48px
    width: 100%;
    background: $n;
    border-radius: 8px;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    @include d {
        flex-direction: column;
        padding: 32px 0; }
    @include m {
        flex-direction: column;
        padding: 24px 0; } }

.half {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 50%;
    flex-wrap: wrap;
    width: calc(50%);
    // width: 100%
    // height: 100%
    // gap: 24px
    margin-top: auto;
    margin-bottom: auto;
    img {
        width: calc(75%);
 }        // display: flex
    @include w {
        // flex-direction: row
        margin-top: 64px;
        padding: 0 64px; }
    @include t {
        // flex-direction: row
        margin: 0;
        padding: 0; }
    &:nth-child(2n+1) {
        border-right: 0px solid $n3;
        @include t {
            // flex-direction: row
            border: none; } }
    &:not(:last-child) {
        @include t {
            // flex-direction: row
            margin-bottom: 48px;
            padding-bottom: 48px;
            border-bottom: 0px solid $n3; } } }

.text {
        width: calc(50%);
        display: flex;
        flex-direction: column;
        align-items: justify-start; }

span {
    color: $p1; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 24px 0;
    border-width: 0px 0;
    border-style: solid;
    border-color: $n3; }

.group {
    flex-grow: 1; }


.button {
    width: 100%;
    margin-top: 72px;
    @include t {
        margin-top: 48px; } }


