@import "../../styles/helpers";

.page {
    padding: 0;
    @include x {
        padding: 0; }
    @include d {
        padding-left: 0; }
    @include t {
        overflow: hidden; }
    @include m {
        padding-left: 0; } }

.sidebar {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }

.menu {
    @include m {
        transform: translateX(-100%);
        transition: transform .3s;
        &.visible {
            transform: translateX(0); } } }

.inner {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 96px);
    padding: 80px 80px 48px;
    @include x {
        padding: 32px 32px; }
    @include m {
        padding: 32px 24px; } }

.container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 1280px;
    padding: 48px auto;
    margin: 48px auto;
    &.wide {
        max-width: 100%; } }

.title {
    margin-bottom: 24px;
    @include m {
        margin-bottom: 16px; } }
