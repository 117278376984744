@import "../../../styles/helpers";

.full {
    overflow-x: hidden;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    .form {
        outline: 1px solid $shades1; } }

.secTitle {
    text-align: center;
    padding-bottom: 32px; }

.helper {
    text-align: center;
    padding: 12px 0;
    font-weight: 500;
    color: $shades1; }

