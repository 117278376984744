@import "../../styles/helpers";

.home {
    background-color: $n;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; } }
