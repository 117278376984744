// media queries
@mixin w {
    @media only screen and (max-width: "1419px") {
        @content; } }
@mixin x {
    @media only screen and (max-width: "1339px") {
        @content; } }
@mixin d {
    @media only screen and (max-width: "1259px") {
        @content; } }
@mixin t {
    @media only screen and (max-width: "1023px") {
        @content; } }
@mixin m {
    @media only screen and (max-width: "767px") {
        @content; } }
@mixin a {
    @media only screen and (max-width: "639px") {
        @content; } }
@mixin s {
    @media only screen and (max-width: "474px") {
        @content; } }

// fonts
@mixin inter {
    font-family: 'Inter', sans-serif; }

// colors
$p1: #6C62F3;
$p2: #83BF6E;
$p3: #E3676C;
$p4: #4C45AB;
$s1: #FD9C57;
$s2: #DAD8FC;
$s3: #B1E5FC;
$s4: #B5E4CA;
$s5: #FEBD46;
$s6: #FFE7E4;

$n: #FFFFFF;
$n1: #FCFCFC;
$n2: #F4F4F4;
$n3: #EFEFEF;
$n4: #6F767E;
$n5: #33383F;
$n6: #272B30;
$n7: #1A1D1F;
$n8: #111315;
$dark: #333333;

$shades1: #9A9FA5;
$shades2: #6F767E;
$shades3: rgba(#6F767E, .4);
$shades4: rgba(#111315, .5);

// typography
@mixin title1-s {
    font-size: 20px;
    font-weight: 600;
    line-height: (32/20);
    letter-spacing: -.02em;
    @include m {
        font-size: 18px; } }

@mixin title1-m {
    font-size: 20px;
    font-weight: 500;
    line-height: (32/20);
    letter-spacing: -.02em;
    @include m {
        font-size: 18px; } }

@mixin title2 {
    font-size: 18px;
    line-height: (24/18);
    letter-spacing: -0.02em; }

@mixin base1-s {
    font-size: 15px;
    font-weight: 600;
    line-height: (24/15); }

@mixin base1-b {
    font-size: 15px;
    font-weight: 700;
    line-height: (24/15); }

@mixin base2 {
    font-size: 14px;
    font-weight: 600;
    line-height: (24/14); }

@mixin body1-m {
    font-size: 15px;
    font-weight: 500;
    line-height: (24/15);
    letter-spacing: -.015em; }

@mixin body1-s {
    font-size: 15px;
    font-weight: 600;
    line-height: (24/15);
    letter-spacing: -.015em; }

@mixin body2-s {
    font-size: 14px;
    font-weight: 600;
    line-height: (24/14); }

@mixin caption1 {
    font-size: 13px;
    font-weight: 600;
    line-height: (16/13); }

@mixin caption1-m {
    font-size: 13px;
    font-weight: 500;
    line-height: (16/13); }

@mixin caption2 {
    font-size: 12px;
    font-weight: 700;
    line-height: (16/12); }

@mixin caption2-m {
    font-size: 12px;
    font-weight: 500;
    line-height: 1; }

@mixin button1 {
    font-size: 15px;
    font-weight: 700;
    line-height: (24/15); }

@mixin button2 {
    font-size: 13px;
    font-weight: 700;
    line-height: (24/13); }

//triangle
@mixin arr($width, $height, $bg, $direction) {
    width: 0px;
    height: 0px;
    border-style: solid;
    @if $direction == t {
        border-width: 0 $width / 2 + px $height + px $width / 2 + px;
        border-color: transparent transparent $bg transparent; }
    @if $direction == r {
        border-width: $height / 2 + px 0 $height / 2 + px $width + px;
        border-color: transparent transparent transparent $bg; }
    @if $direction == b {
        border-width: $height + px $width / 2 + px 0 $width / 2 + px;
        border-color: $bg transparent transparent transparent; }
    @if $direction == l {
        border-width: $height / 2 + px $width + px $height / 2 + px 0;
        border-color: transparent $bg transparent transparent; }
    @if $direction == tl {
        border-width: $height + px $width + px 0 0;
        border-color: $bg transparent transparent transparent; }
    @if $direction == tr {
        border-width: 0 $width + px $height + px 0;
        border-color: transparent $bg transparent transparent; }
    @if $direction == br {
        border-width: 0 0 $height + px $width + px;
        border-color: transparent transparent $bg transparent; }
    @if $direction == bl {
        border-width: $width + px 0 0 $height + px;
        border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
@mixin placeholder {
    &::placeholder {
        @content; } }

// media query width
@mixin r($width) {
    @media only screen and (max-width: $width+ "px") {
        @content; } }

@mixin rmin($width) {
    @media only screen and (min-width: $width+ "px") {
        @content; } }

// theme
@mixin dark {
    @at-root :global(.dark-mode) & {
        @content; } }

@mixin dark-body {
    @at-root .dark-mode {
        @content; } }

@mixin dark-common {
    @at-root .dark-mode & {
        @content; } }
