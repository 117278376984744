@import "../../../styles/helpers";

.row {
    display: flex;
    @include t {
        display: block; } }

.col {
    &:first-child {
        flex: 0 0 calc(50%);
        width: calc(50%);
        padding-right: 8px;
        @include x {
            flex: 0 0 calc(50%);
            width: calc(50%); }
        @include d {
            flex: 0 0 calc(50%);
            width: calc(100% - 312px); }
        @include t {
            width: 100%;
            margin-bottom: 8px;
            padding-right: 0; } }
    &:nth-child(2) {
        flex-shrink: 0;
        width: cal(50%);
        @include x {
            width: cal(50%); }
        @include d {
            width: cal(50%); }
        @include t {
            width: 100%; } } }


.list {
    display: flex;
    flex-wrap: wrap;
    margin: -80px -80px 0;
    @include w {
        margin: -64px -64px 0; }
    @include t {
        display: block;
        margin: 0; } }


.full {
    padding: 48px;
    margin: 80px 0;
    background: $n;
    border-radius: 8px;
    // overflow: hidden
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; }
    @include d {
        padding: 32px; }
    @include m {
        padding: 24px; } }

.half {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    margin-top: 48px;
    padding: 0 48px;
    // img
    //     display: flex
    //     flex: 0 0 50%
    //     width: 100%
    //     height: 100%
    @include w {
        margin-top: 64px;
        padding: 0 64px; }
    @include t {
        margin: 0;
        padding: 0; }
    &:nth-child(2n+1) {
        border-right: 0px solid $n3;
        @include t {
            border: none; } }
    &:not(:last-child) {
        @include t {
            margin-bottom: 48px;
            padding-bottom: 48px;
            border-bottom: 0px solid $n3; } } }

.top {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 24px; }

.content {
    margin-bottom: 24px;
    margin-top: 24px;
    color: $n4; }

span {
    color: $p1; }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 24px 0;
    border-width: 0px 0;
    border-style: solid;
    border-color: $n3; }

.percent {
    margin-right: 24px; }

.note {
    position: relative;
    max-width: 190px;
    margin-right: auto;
    font-weight: 400;
    @include caption1;
    color: $shades1; }

.group {
    flex-grow: 1; }

.item {
    position: relative;
    padding-left: 40px;
    color: $n5;
    @include dark {
        color: $n3; }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-8.586 8.586a3 3 0 0 1-4.243 0l-3.586-3.586a1 1 0 0 1 1.414-1.414l3.586 3.586a1 1 0 0 0 1.414 0l8.586-8.586a1 1 0 0 1 1.414 0z' fill='%23b5e4ca'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; }
    &:not(:last-child) {
        margin-bottom: 24px; } }

.button {
    width: 100%;
    margin-top: 72px;
    @include t {
        margin-top: 48px; } }

.message {
    margin-top: 40px; }

.secTitle {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px; }
